/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";
// import { stringify } from "qs";

// 获取商品列表
const getItemList = async (currentPage, params) =>
  request({
    url: `/frontCategory/getPagerItemByFrontCategoryId? currentPage=${currentPage}&qp-frontCategoryId-eq=${params}&`,
    method: "get",
  });

const getTotalItemList = async (currentPage, brandId, classid, spuid) => {
  let url = "";
  if (brandId) {
    url = `/construct/getItemList?currentPage=${currentPage}&qp-brandId-in=${brandId.toString()}`;
  } else if (classid) {
    url = `/construct/getItemList?currentPage=${currentPage}&qp-classId-in=${classid.toString()}`;
  } else if (spuid) {
    url = `/construct/getItemList?currentPage=${currentPage}&qp-id-in=${spuid.toString()}`;
  } else {
    url = `/construct/getItemList?currentPage=${currentPage}`;
  }

  return await request({
    url,
    method: "get",
  });
};

export { getItemList, getTotalItemList };
